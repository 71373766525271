import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ContractService} from '../../../../Service/contract.service';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';

@Component({
  selector: 'app-contract-create-dialog-document',
  templateUrl: './contract-create-dialog-document.component.html',
  styleUrls: ['./contract-create-dialog-document.component.scss']
})
export class ContractCreateDialogDocumentComponent implements OnInit, AfterViewInit {

  @Input() contractForm;
  @ViewChild('fileInput',  {static: false}) fileInput;
  @ViewChild('templateSearch', {static: false}) templateSearch;

  templates;

  signTypes = [
    {
      label: 'Simple',
      value: 0
    },
    {
      label: 'Avancée',
      value: 1
    },
    {
      label: 'Qualifiée',
      value: 0
    },
    {
      label: 'Qualifiée à distance',
      value: 3
    }
  ];

  constructor(
    private _contract: ContractService
  ) { }

  ngOnInit() {
    this.getCustomCMTemplates();
  }

  ngAfterViewInit() {
    fromEvent(this.templateSearch.nativeElement,'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.getCustomCMTemplates(this.templateSearch.nativeElement.value);
        })
      )
      .subscribe();
  }

  getCustomCMTemplates(search = '') {
    this._contract.getCustomCMTemplate(search).subscribe(
      (results) => {
        this.templates = results['results'];
        this.templates.map((template) => {
          template.isSelected = false;
        });
      }
    );
  }

  selectTemplate(template) {
    this.contractForm.controls.template.setValue(template);
  }

  onChangeSignType(event) {
    if (event.target.value == 3) {
      this.contractForm.controls.isPdfTag.setValue(true);
    }
  }

  onChangeIsPdfTags() {
    if (this.contractForm.value.signType == 3) {
      this.contractForm.controls.isPdfTag.setValue(true);
    }
  }

}
