import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ContractService } from "../../../Service/contract.service";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { User } from "../../../Model/User";
import { UserService } from "../../../Service/user.service";
import { HttpClient } from "@angular/common/http";
import { Contact } from "../../../Model/Contact";
import { ContactService } from "../../../Service/contact.service";
import { Observable } from "rxjs";
import { NotificationService } from "../../../Service/notification.service";

@Component({
  selector: "app-contract-create-dialog",
  templateUrl: "./contract-create-dialog.component.html",
  styleUrls: ["./contract-create-dialog.component.scss"],
})
export class ContractCreateDialogComponent implements OnInit {
  previewForm: null;
  contractForm: FormGroup = new FormGroup({
    subject: new FormControl("", [Validators.required]),
    isPdfTag: new FormControl("", []),
    requireAttachments: new FormControl("", []),
    eidasTimestamp: new FormControl("", []),
    isCertified: new FormControl("", []),
    isHandwrittenSignature: new FormControl("", []),
    contacts: new FormControl([], []),
    copy_contacts: new FormControl([], []),
    copyRecipients: new FormArray([
      new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
        firstname: new FormControl("", [Validators.required]),
        lastname: new FormControl("", [Validators.required]),
        phone_mobile: new FormControl("", [
          Validators.pattern(new RegExp("^((\\+)33|0|0033)[1-9](\\d{2}){4}$")),
        ]),
      }),
    ]),
    recipients: new FormArray([
      new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
        firstname: new FormControl("", [Validators.required]),
        lastname: new FormControl("", [Validators.required]),
        phone_mobile: new FormControl("", [
          Validators.pattern(new RegExp("^((\\+)33|0|0033)[1-9](\\d{2}){4}$")),
        ]),
        comment: new FormControl("", []),
        signPosition: new FormControl(null, []),
        search: new FormControl("", []),
        hasMandate: new FormControl(false, []),
      }),
    ]),
    attachments: new FormArray([], [Validators.required]),
    signType: new FormControl(0, [Validators.required]),
    template: new FormControl(null, []),
  });

  signTypes = [
    {
      label: "Simple",
      value: 0,
    },
    {
      label: "Avancée",
      value: 1,
    },
    {
      label: "Qualifiée",
      value: 0,
    },
    {
      label: 'Qualifiée à distance',
      value: 3
    }
  ];

  step = 1;
  maxStep = 3;

  usePrefillDocument = null;

  contacts;
  contacts_filtered: Observable<Contact[]>[] = [];
  // @ViewChild('searchContact', {static: false}) searchContact;

  constructor(
    private _contract: ContractService,
    public dialogRef: MatDialogRef<ContractCreateDialogComponent>,
    private _user: UserService,
    private _http: HttpClient,
    private _contact: ContactService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this._user.getUser().subscribe((user: User) => {
      const feature = user.userPaidFeatures.filter((feature) => {
        return feature.feature.name === "PDF TAG";
      })[0];
      if (feature.enabledAt) {
        this.contractForm.controls.isPdfTag.setValue(true);
      }
    });

    this.getContacts();
  }

  close() {
    this.dialogRef.close();
  }

  addRecipient() {
    const control = this.contractForm.controls.recipients as FormArray;
    control.push(
      new FormGroup({
        email: new FormControl("", [Validators.required]),
        firstname: new FormControl("", [Validators.required]),
        lastname: new FormControl("", [Validators.required]),
        phone_mobile: new FormControl("", []),
        comment: new FormControl("", []),
        signPosition: new FormControl(null, []),
        search: new FormControl("", []),
        hasMandate: new FormControl(false, []),
      })
    );
    this.getContacts();
  }

  deleteRecipient(id) {
    const control = this.contractForm.controls.recipients as FormArray;
    control.removeAt(id);
  }

  onFileChange(event) {
    this.addFiles(event.target.files);
  }

  onFileDropped(files) {
    this.addFiles(files);
  }

  addFiles(files) {
    const control = this.contractForm.controls.attachments as FormArray;
    control.clear();

    Array.from(files).forEach((file) => {
      if (file["type"] !== "application/pdf") {
        this.contractForm.controls.attachments.setErrors({
          type: true,
        });
        return;
      }
      const filename = file["name"];
      const fileContent = file as Blob;

      control.push(
        new FormGroup({
          filename: new FormControl(filename, []),
          content: new FormControl(fileContent, []),
        })
      );
    });
  }

  getFormRecipients() {
    return this.contractForm.controls.recipients as FormArray;
  }

  getContacts() {
    this._contact.getContacts().subscribe((contacts) => {
      this.contacts = contacts["hydra:member"];
      this.contacts.map(
        (contact) =>
          (contact.fullname = `${contact.firstname} ${contact.lastname}`)
      );
    });
  }

  fillContact(contact: Contact, index) {
    const formArray = this.contractForm.controls.recipients as FormArray;
    const recipient = formArray.controls[index] as FormGroup;

    recipient.controls.email.setValue(contact.email);
    recipient.controls.firstname.setValue(contact.firstname);
    recipient.controls.lastname.setValue(contact.lastname);
    recipient.controls.phone_mobile.setValue(contact.phoneMobile);
  }

  toogleMandate(i) {
    const recipients = this.contractForm.controls.recipients as FormArray;
    const control = recipients.controls[i] as FormGroup;
    const hasMandate = control.controls.hasMandate.value;
    if (hasMandate) {
      control.addControl(
        "mandate",
        new FormGroup({
          email: new FormControl("", [Validators.required, Validators.email]),
          firstname: new FormControl("", [Validators.required]),
          lastname: new FormControl("", [Validators.required]),
          phone_mobile: new FormControl("", [
            Validators.pattern(
              new RegExp("^((\\+)33|0|0033)[1-9](\\d{2}){4}$")
            ),
          ]),
        })
      );
    } else {
      control.removeControl("mandate");
    }
  }

  nextStep() {
    if (this.canGoNextStep()) {
      if (this.step === this.maxStep) {
        this.submit();
        return;
      }
      this.step++;
    }
  }

  previousStep() {
    if (this.step === 1) {
      this.close();
      return;
    }
    this.step--;
  }

  canGoNextStep() {
    if (this.step === 1) {
      return this.isStepValid(1);
    }
    if (this.step === 2) {
      return this.isStepValid(1) && this.isStepValid(2);
    }
    return true;
  }

  isStepValid(step) {
    if (step === 1) {
      return this.usePrefillDocument !== null;
    }
    if (step === 2) {
      const file = this.contractForm.controls.attachments as FormControl;
      return (
        (file.valid || this.contractForm.controls.template.value) &&
        this.contractForm.controls.subject.valid
      );
    }
    if (step === 3) {
      return this.contractForm.controls.contacts.value.length > 0;
    }
  }

  submit() {
    const contract = this.contractForm.value;
    contract.recipients = [];
    contract.copyRecipients = [];

    if (this.contractForm.value.signType == 3) {
      let signPosition = 1;
      const ordered = contract.contacts.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })
      let ok = true;
      ordered.forEach((contact) => {
        console.log(contact.signPosition, signPosition);
        if (contact.signPosition != signPosition) {
          ok = false;
        }
        signPosition++;
      });

      if (!ok) {
        this.notification.send("error", "En cas de signature qualifiée à distance chaque signataire doit avoir un rang unique");
        return false;
      }
    }
    contract.copy_contacts.forEach((contact) => {
      contract.copyRecipients.push({
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        phone_mobile: contact.phoneMobile,
      });
    });
    contract.contacts.forEach((contact) => {
      contract.recipients.push({
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        phone_mobile: contact.phoneMobile,
        comment: contact.comment,
        signPosition: contact.signPosition,
      });
    });

    this._user.getUser().subscribe((user: User) => {
      if (this.contractForm.value.template) {
        const contract = this.contractForm.value;
        const recipients = [];
        contract.contacts.forEach((contact) => {
          recipients.push({
            contactId: contact.id,
            email: contact.email,
            firstname: contact.firstname,
            lastname: contact.lastname,
            phone_mobile: contact.phoneMobile,
            comment: contact.comment,
            signPosition: contact.signPosition,
          });
        });

        const fileNameBuild = this.contractForm.value.attachments && this.contractForm.value.attachments[0]
          ? this.contractForm.value.attachments[0].fileName
          : 'document';

        const data = {
          templateType: this.contractForm.value.template.type,
          documentTemplateId: this.contractForm.value.template.id,
          recipients,
          copyRecipients: this.contractForm.value.copyRecipients,
          vitalsignOptions: {
            isPdfTag: this.contractForm.value.isPdfTag,
            requireAttachments: this.contractForm.value.requireAttachments,
            eidasTimestamp: this.contractForm.value.eidasTimestamp,
            isCertified: this.contractForm.value.isCertified,
            isHandwrittenSignature: this.contractForm.value.isHandwrittenSignature,
            subject: this.contractForm.value.subject,
            signType: this.contractForm.value.signType,
            fileName: fileNameBuild,
          },
        };
        this._contract.sendDraftContracts(data).subscribe(
          (result) => {
            this.notification.send("success", "Contrat créé avec succès !");
            this.dialogRef.close();
            window.location.reload();
          },
          (err) => {
            this.notification.send("error", err.error["message"]);
          }
        );
      } else {
        this._contract.createContract(contract, user.apiKey).subscribe(
          (result) => {
            this.notification.send("success", "Contrat bien créé !");
            this.dialogRef.close();
            window.location.reload();
          },
          (err) => {
            switch (err.status) {
              case 409:
                let data = JSON.parse(err.error["hydra:description"]);
                // this.notification.send(
                //   "error",
                //   err.name + " : " + err.statusText
                // );
                this.previewForm = data;
                this.step = 4;
                console.log({
                  step: this.step,
                  err,
                  previewForm: this.previewForm,
                });
                break;

              default:
                this.notification.send("error", err.error["hydra:description"]);
                break;
            }
          }
        );
      }
    });
  }

  setDocumentType(event) {
    if (this.usePrefillDocument !== event) {
      const control = this.contractForm.controls.attachments as FormArray;
      control.clear();
      this.contractForm.controls.template.setValue(null);
      this.usePrefillDocument = event;
    }
    this.nextStep();
  }
}
